let APILXD = {
    /**
     * 通用
     */
    //上传文件
    upLoad: '/common/upload',
    //获取上传文件的token(阿里，七牛)
    getUploadToken: '/admin/upload/getUploadToken',


    /**
     * 后台账号管理
     */
    //登陆
    login: '/admin/admin/login',
    //获取密码随机串
    getRandSalt: '/admin/admin/getRandSalt',
    //后台账号列表
    getAdminList: '/admin/admin/getAdminList',
    //新增账号
    addAdmin: '/admin/admin/addAdmin',
    //查询账号详情
    getAdminDetail: '/admin/admin/getAdminDetail',
    //编辑账号
    editAdmin: '/admin/admin/editAdmin',
    //删除账号
    delAdmin: '/admin/admin/delAdmin',
    //退出登陆
    logoutAdmin: '/admin/admin/logoutAdmin',
    //修改密码
    modfiyPasswd: '/admin/admin/modfiyPasswd',
    //启用、禁用管理员
    enableAdmin: '/admin/admin/enableAdmin',

    /**
     * 项目
     */
    //获取所有项目
    getAllProjects: '/admin/docProject/getAllProjects',
    //新增项目
    addProject: '/admin/docProject/addProject',
    //编辑项目
    editProject: '/admin/docProject/editProject',
    //删除项目
    delProject: '/admin/docProject/delProject',

    /**
     * 目录
     */
    //获取项目下的目录结构
    getGroupByProid: '/admin/docGroup/getGroupByProid',
    //新增目录
    addGroup: '/admin/docGroup/addGroup',
    //编辑目录
    editGroup: '/admin/docGroup/editGroup',
    //删除目录
    delGroup: '/admin/docGroup/delGroup',
    //排序
    updateDocSortNo: '/admin/docContent/updateDocSortNo',
    //修改项目发布状态
    setProjectStatus: '/admin/docProject/setProjectStatus',

    /**
     * 文档
     */
    //新增文档
    addContent: '/admin/docContent/addContent',
    //编辑文档
    editContent: '/admin/docContent/editContent',
    //删除文档
    delContent: '/admin/docContent/delContent',
    //根据id获取文档
    getContentById: '/admin/docContent/getContentById',
    //获取项目的文档树
    getDocTree: '/admin/docContent/getDocTree',
    //搜索文档
    searchDoc: '/admin/docContent/searchDoc',
    //批量移动文档到另一个目录下
    moveDocToGroup: '/admin/docContent/moveDocToGroup',
    //刷新搜索引擎中的文档
    refreshAllContentToES: '/admin/docContent/refreshAllContentToES',
    //设置文档发布状态
    setCotentStatus: '/admin/docContent/setCotentStatus',
};

module.exports = APILXD;
