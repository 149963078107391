import React from 'react'
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import DocPageForUser from "./main/navigationPages/docPageForUser/DocPageForUser";
import DocPageForAdmin from "./main/navigationPages/docPageForAdmin/DocPageForAdmin";

import App from './main/App.js';
let routes = (<Router>
    <Switch>
        <Route path="/admin">
            <App/>
        </Route>
        <Route path="/docManage">
            <DocPageForAdmin/>
        </Route>
        <Route path="/">
            <DocPageForUser/>
        </Route>
    </Switch>
</Router>);

export default routes;

