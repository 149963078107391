import React from 'react';
import less from './Test.less';
import {Button, message, Spin, Tabs, Modal} from 'antd';
import UploadHelp from "../../../components/upLoad/UploadHelp";
import E from 'wangeditor'
class Test extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };

        this.upload = new UploadHelp();
    }

    componentDidMount() {
        this.editor = new E("#editBar", "#editArea");
        //自定义

        console.log(this.editor.config);

        let setConfigObj = {
            uploadImgMaxLength: 1,
            menuTooltipPosition: "down",
            colors: [
               "#000000",
               "#434343",
               "#595959",
               "#8c8c8c",
               "#bfbfbf",
               "#d9d9d9",
               "#f0f0f0",
               "#ffffff",

               "#850018",
               "#ab001a",
               "#d0021b",
               "#de2635",
               "#eb4d55",
               "#f7797b",
               "#ffa7a6",
               "#ffe7e6",

               "#824300",
               "#a86007",
               "#cf8013",
               "#f5a623",
               "#ffc14d",
               "#ffd375",
               "#ffe49e",
               "#fff1c7",

               "#856c00",
               "#ab9102",
               "#d1ba0d",
               "#f8e71c",
               "#fff645",
               "#fffd6e",
               "#fdff96",
               "#fcffbf",

               "#163b00",
               "#296101",
               "#418708",
               "#5ead13",
               "#7ed321",
               "#baed72",
               "#d7faa0",
               "#edffcc",

               "#167066",
               "#269686",
               "#39bda4",
               "#50e3c2",
               "#7af0d2",
               "#a9fce5",
               "#d4fff1",
               "#f0fffa",

               "#143670",
               "#235196",
               "#3570bd",
               "#4a90e2",
               "#75b3f0",
               "#a4d3fc",
               "#cfeaff",
               "#f0f9ff",

               "#5300b3",
               "#6f04d9",
               "#9013fe",
               "#aa3bff",
               "#c163ff",
               "#d58cff",
               "#e6b5ff",
               "#f7e6ff",
            ],
            showLinkImg: true,
            showLinkImgAlt: false,
            showLinkImgHref: true,
            uploadImgAccept: ['jpg', 'jpeg', 'png', 'gif'],
            customUploadImg: (files, insert) => {
                log(files);
                let file = files[0];

                let successCB = (code, msg, json, option) => {
                    insert(json.url);
                    this.setState({
                        loading: false,
                    });
                };
                let failureCB = (code, msg) => {
                    message.error('上传图片失败');
                    this.setState({
                        loading: false,
                    });
                };

                let suffixList = file && file.name && file.name.split('.');
                if (suffixList.length <= 1) {
                    message.warning('文件选择出错');
                    return;
                }
                let suffix = suffixList[suffixList.length - 1];
                if (!suffix) {
                    message.warning('文件选择出错');
                    return;
                }

                this.setState({
                    loading: true,
                }, () => {
                    this.upload.upload(file, suffix, successCB, failureCB);
                });
            },
            showLinkVideo: true,
            customUploadVideo: (files, insert)=>{
                log(files);
                let file = files[0];

                let successCB = (code, msg, json, option) => {
                    insert(json.url);
                    this.setState({
                        loading: false,
                    });
                };
                let failureCB = (code, msg) => {
                    message.error('上传视频失败');
                    this.setState({
                        loading: false,
                    });
                };

                let suffixList = file && file.name && file.name.split('.');
                if (suffixList.length <= 1) {
                    message.warning('文件选择出错');
                    return;
                }
                let suffix = suffixList[suffixList.length - 1];
                if (!suffix) {
                    message.warning('文件选择出错');
                    return;
                }

                this.setState({
                    loading: true,
                }, () => {
                    this.upload.upload(file, suffix, successCB, failureCB);
                });
            },
            customAlert: (s, t)=>{
                switch (t) {
                    case 'success':
                        message.success(s)
                        break
                    case 'info':
                        message.info(s)
                        break
                    case 'warning':
                        message.warning(s)
                        break
                    case 'error':
                        message.error(s)
                        break
                    default:
                        message.info(s)
                        break
                }
            }
        };

        this.editor.config = Object.assign(this.editor.config,setConfigObj);
        this.editor.create();
    }


    render() {
        return (<div className={less.mainPage}>
            <div className={less.topMsgBox}>
                <Button
                    type={"primary"}
                    onClick={()=>{this.toSubmit()}}>输出</Button>
            </div>
            <div className={less.centerMsgBox}>
                <div id={'editBar'} className={less.editBar}></div>
            </div>
            <div className={less.bottomMsgBox}>
                <div id={'editArea'} className={less.editArea}></div>
            </div>
        </div>);
    }

    /**
     * 提交
     */
    toSubmit() {
        let content = this.editor.txt.html();
        let text = this.editor.txt.text();

        log(content);
        log(text);
    }
}

export default Test;