import React from "react";
import less from "./DocPage.less";
import HttpTool from "../../../tool/HttpTool";
import APILXD from "../../../http/APILXD";
import {Modal, Empty, Button, Menu, Dropdown, message,Spin, Input, Pagination, Space} from "antd";
import ModalBase from "../../../components/modalBase/index";
import {EyeOutlined, FieldTimeOutlined, FolderOutlined, SearchOutlined, SettingOutlined} from '@ant-design/icons';
import FiltersIndexSet from "./indexSet/FiltersIndexSet";
import ModalConfig from "../../../components/modalConfig/index";
import TimeHelp from "../../../tool/TimeHelp";
import MyMarkDown from "./markdown/MyMarkDown";
import MarkDownShow from "./markdown/MarkDownShow";

const MB = new ModalBase();
const {SubMenu, Item} = Menu;
const {Search} = Input;

class DocPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            docTree: null,
            currentSelected: this.props.contentID?"doc_" + this.props.contentID:null,
            openKeys: null,
            currentDocObj: null,
            showEshowEmptympty: false,
            loading: false,

            ifEdit: false,

            searchLoading: false,
            searchResult: null,

            hockResult: null,

            pageSize: 5,
            page: 1,
            total: null,
        }

        this.powerForGroupManage = this.props.powerForGroupManage || {};
        this.powerForContentManage = this.props.powerForContentManage || {};

        this.getDocTreeAPI = "/app/doc/getDocTree";
        this.getContentByIdAPI = "/app/doc/getContentById";
        this.searchDocAPI = "/app/doc/searchDoc";

        if (this.props.from === "admin") {
            //来自后台管理，API地址修改
            this.getDocTreeAPI = APILXD.getDocTree;
            this.getContentByIdAPI = APILXD.getContentById;
            this.searchDocAPI = APILXD.searchDoc;
        }
    }

    componentDidMount() {
        this.needLoadDoc = true;
        this.getDocTree();
    }

    /**
     * 统一的请求展示交互，目前是loading
     * @param path          请求地址
     * @param param         请求参数
     * @param successCB     成功的回调
     * @param failureCB     失败的回调
     * @param showAction    是否展示交互，默认打开
     */
    commonRequest(path, successCB, failureCB, param, showAction = true) {
        if (!showAction) {
            HttpTool.post(path, successCB, failureCB, param);
        } else {
            let newSuccessCB = (code, msg, obj, option) => {
                this.setState({
                    loading: false,
                }, () => {
                    successCB && successCB(code, msg, obj, option);
                })
            };
            let newFailureCB = (code, msg, obj, option) => {
                this.setState({
                    loading: false,
                }, () => {
                    failureCB && failureCB(code, msg, obj, option);
                })
            };

            this.setState({
                loading: true
            }, () => {
                HttpTool.post(path, newSuccessCB, newFailureCB, param);
            })
        }
    }

    //获取项目文档树
    getDocTree() {
        if (!this.props.projectID) {
            this.setState({
                showEmpty: "未查询到任何项目"
            })
        } else {
            this.commonRequest(this.getDocTreeAPI, (code, msg, json) => {
                this.setState({
                    docTree: json
                })

                if(this.needLoadDoc){
                    this.needLoadDoc = false;
                    this.getDocContent();
                }
            }, (code, msg) => {
                this.setState({
                    showEmpty: msg
                })
                Modal.error({
                    title: "错误",
                    content: msg
                })
            }, {
                proid: this.props.projectID
            })
        }
    }

    getOpenKeys(docId){
        let {docTree} = this.state;

        if(!docTree){
            return [];
        }

        let pathArr = this.getNodeDeepPath(docTree,(obj)=>{return obj.type === 1 && obj.id === docId}).filter((o)=>{return o.type === 2});

        console.log("pathArr")
        console.log(pathArr)

        return pathArr.map((p)=>{
            return "group_" + p.id
        })
    }

    /**
     * 获取从根到目标节点经过的所有节点
     * @param treeData 搜索的数据
     * @param filter
     * @param childField    子集键名
     * @return {*}
     */
    getNodeDeepPath = (treeData, filter, childField = "child") => {
        let pathArr = [];
        if (treeData) {
            let find = (treeNode) => {
                if (treeNode) {
                    pathArr.push(treeNode);
                    if (filter(treeNode)) {
                        return true;
                    } else if (treeNode[childField]) {
                        for (let i = 0, l = treeNode[childField].length; i < l; i++) {
                            if (find(treeNode[childField][i])) {
                                return true;
                            }
                        }
                    }

                    //没找到，把最后一个剔除
                    pathArr.pop();
                    return false;
                } else {
                    return false;
                }
            };
            if (Array.isArray(treeData)) {
                for (let i = 0, l = treeData.length; i < l; i++) {
                    if (find(treeData[i])) {
                        break;
                    }
                }
            } else {
                find(treeData);
            }
            return pathArr;
        } else {
            return pathArr;
        }
    };

    //通过文档id查询内容
    getDocContent(cb) {
        let {currentSelected} = this.state;
        if(!currentSelected){
            return;
        }

        console.log(currentSelected)

        this.commonRequest(this.getContentByIdAPI, (code, msg, json) => {
            let openKeys = this.getOpenKeys(json.id);

            this.setState({
                currentDocObj: json,
                hockResult: null,
                openKeys: openKeys
            },()=>{
                this.getHockTitleShow();
                cb && cb()
            })
            //设置地址
            ___.changeUrl({
                projectID: this.props.projectID,
                contentID: json.id
            })
        }, (code, msg) => {
            Modal.error({
                title: "错误",
                content: msg
            })
        }, {
            id: Number(currentSelected.split("_")[1])
        })
    }

    //匹配出导航标签内容
    getHockTitleShow(){
        let hockArr = document.getElementsByClassName("hockTitle");

        if(hockArr && hockArr.length){
            let hockResult = Array.from(hockArr).map((item, index)=>{
                return {
                    title: item.innerText,
                    index: index,
                }
            })

            this.setState({
                hockResult: hockResult
            })
        }
    }

    //搜索
    searchDoc(){
        let {pageSize, page, searchValue} = this.state;

        if(!searchValue){
            this.setState({
                searchResult: null,
                searchLoading:false,
                page: 1,
            })

            return;
        }

        this.setState({
            searchLoading: true
        })

        HttpTool.post(this.searchDocAPI,(code, msg, json, option)=>{
            this.setState({
                searchResult: json,
                searchLoading:false,
                page: page,
                total: option.option
            })
        },(code, msg)=>{
            this.setState({
                searchResult: null,
                searchLoading:false,
                page: 1,
            })
            Modal.error({
                title: "搜索出错",
                content: msg
            })
        },{
            page: page,
            pageSize: pageSize,
            keyword: searchValue,
            proid: this.props.projectID
        })
    }

    render() {
        let {loading, showEmpty, hockResult} = this.state;

        return <div className={less.mainPage}>
            {
                loading
                    ?<div className={less.spinStyle}><Spin size={"large"} spinning={true}></Spin></div>
                    :null
            }
            {
                showEmpty
                    ? <Empty
                        style={{marginTop: "200px"}}
                        description={showEmpty}
                    />
                    : <div>
                        <div className={less.headBox}>
                        </div>
                        <div className={less.contentBox}>
                            <div className={less.navBox}>
                                <div className={less.mainTitle}>
                                    <span className={less.logo}></span>
                                    文档中心
                                </div>
                                <div className={less.searchBox}>
                                    <Search
                                        prefix={<SearchOutlined />}
                                        placeholder="输入关键词搜索"
                                        enterButton="搜索"
                                        loading={this.state.searchLoading}
                                        onSearch={(value)=>{
                                            if(!value){
                                                message.info("请输入关键词");
                                                return;
                                            }
                                            this.searchDoc(value)
                                        }}
                                        onChange={(e)=>{
                                            this.setState({
                                                searchValue: e.target.value
                                            }, ()=>{
                                                if(!e.target.value){
                                                    this.searchDoc()
                                                }
                                            })
                                        }}
                                    />
                                </div>
                                {this.getNavView()}
                            </div>
                            <div className={less.docContentBox}
                                onClick={()=>{
                                    this.setState({
                                        searchResult: null
                                    })
                                }}
                            >
                                {this.getDocContentView()}

                                {
                                    hockResult
                                        ?this.getHockTitleView()
                                        :null
                                }
                            </div>
                        </div>
                        <div className={less.footerBox}></div>
                        {this.getSearchView()}
                    </div>
            }
        </div>
    }

    getHockTitleView(){
        let {hockResult} = this.state;

        return <div className={less.hockBox}>
            {
                hockResult.map((hockItem,index)=>{
                    return <div
                        key={"hc_" + hockItem.index}
                        className={less.hockItemBox}
                        onClick={()=>{
                            let arr = document.getElementsByClassName("hockTitle");
                            let jumpTitle = arr[hockItem.index];

                            jumpTitle.scrollIntoView({behavior: "smooth", block: "start", inline: "start"})
                        }}
                    >
                        {hockItem.title}
                    </div>
                })
            }
        </div>
    }

    getDocContentView(){
        let {currentDocObj, ifEdit} = this.state;

        if(!currentDocObj){
            return <Empty
                style={{marginTop: "200px"}}
                description={"未选择文档"}
            />
        }


        if(ifEdit){
            return <div className={less.editFixBox}>
                <MyMarkDown
                  ref={ref=>this.myMarkDown = ref}
                  locale={"cn"}
                  locaplaceholder={"请输入文档内容"}
                  value={currentDocObj.content}
                  // onChange={(contentStr)=>{
                  //     console.log(contentStr)
                  // }}
                />
                <div className={less.controlBtnBox}>
                    <Space>
                        <Button size={"small"}
                            type={"danger"}
                                ghost={true}
                                style={{width: "100px"}}
                                onClick={()=>{
                                    Modal.confirm({
                                        title: "提示",
                                        content: <div>是否<span style={{color: "red"}}>退出</span>编辑模式？未保存的内容将<span style={{color: "red"}}>丢失</span></div>,
                                        onOk: ()=>{
                                            this.setState({
                                                ifEdit: false
                                            })
                                        }
                                    })
                                }}
                        >取消</Button>
                        <Button size={"small"}
                                type={"primary"}
                                style={{width: "100px"}}
                                onClick={()=>{
                                    let v = this.myMarkDown?.getValue()
                                    if(v){
                                        this.saveDocContent(v)
                                    }else{
                                        message.warn("无法提交")
                                    }
                                }}
                        >提交</Button>
                    </Space>
                </div>
            </div>
        }else{
            return <div className={less.contentFixBox}>
                <div className={less.contentTitle}>{currentDocObj.title}</div>
                <div className={less.contentView}>
                    <FieldTimeOutlined
                        style={{
                            fontSize: "16px",
                            marginRight: "8px",
                        }}
                    />
                    {TimeHelp.getYMDHM((currentDocObj.updatetime || currentDocObj.addtime), true)}
                    <EyeOutlined
                        style={{
                            fontSize: "16px",
                            marginRight: "8px",
                            marginLeft: "60px"
                        }}
                    />
                    {___.countView(currentDocObj.views)}
                </div>
                <div className={less.markDownShow}>
                    <MarkDownShow
                      value={currentDocObj.content}
                    />
                </div>
            </div>
        }
    }

    getSearchView(){
        let {searchResult, total, page} = this.state;

        if(!searchResult){
            return null
        }

        let view;
        if(!searchResult.length){
            view = <Empty
                style={{marginTop: "20px"}}
                description={"无匹配结果"}
            />
        }else{
            view = [];
            searchResult.forEach((item, index)=>{
                view.push(<div
                    key={"sr_" + item.id + index}
                    className={less.searchItemBox}
                    onClick={()=>{
                        this.setState({
                            searchResult: null,
                            currentSelected: "doc_" + item.id,
                        },()=>{
                            this.getDocContent()
                        })
                    }}
                >
                    <div>
                        {item.content && item.content.map((matchStr, index)=>{
                            return <div
                                key={"src_" + index}
                                className={less.fragementItem}
                            >
                                <span dangerouslySetInnerHTML={{__html: matchStr}}></span>
                                <span>...</span>
                            </div>
                        })}
                    </div>
                    <div className={less.pathStyle}>
                        {item.paths && item.paths.groups && item.paths.groups.map((pathItem, index)=>{
                            return <span
                                key={"srp_" + index}
                            >
                                {pathItem.gname}
                                <span> > </span>
                            </span>
                        })}
                        <span dangerouslySetInnerHTML={{__html: item.title}}></span>
                    </div>
                    <div className={less.viewMsg}>
                        <EyeOutlined/>
                        &nbsp;
                        {___.countView(item.views)}
                    </div>
                </div>)
            })
        }

        return <div className={less.searchResult}>
            <div className={less.searchResultInner}>
                {view}
            </div>
            {
                total && total > 5
                    ?<div className={less.pBox}>
                        <Pagination
                            current={this.state.page}
                            pageSize={this.state.pageSize}
                            size={"small"}
                            total={total}
                            onChange={(page, pageSize)=>{
                                this.setState({
                                    page: page,
                                    pageSize: pageSize
                                },()=>{
                                    this.searchDoc()
                                })
                            }}
                        />
                    </div>
                    :null
            }
        </div>
    }


    editDocStatus(data){

        MB.show(
            {
                title: "修改文档状态",
                okTitle: "保存",
                closeTitle: "取消",
            },
            {
                colCount: 1,
                formItemLayout: {
                    labelCol: {span: 8},
                    wrapperCol: {span: 10, offset: 0},
                },
                parameterArr: [
                    {
                        field: "title",
                        name: '文档名称',
                        reg: /^[\s\S]{1,50}$/,
                        option: {
                            defaultValue: data && data.title,
                        }
                    },
                    {
                        field: "status",
                        type: "select",
                        selectType: "value",
                        name: '发布状态',
                        reg: (v)=>{
                            return !!v
                        },
                        option: {
                            placeholder: '请选择发布状态',
                            defaultValue: data ? {
                                key: "" + data.status,
                                value: "" + data.status,
                            }:undefined,
                        },
                        data: [
                            {
                                title: <span style={{color: "limegreen"}}>发布</span>,
                                value: "1",
                            },
                            {
                                title: <span style={{color: "darkorange"}}>不发布</span>,
                                value: "2",
                            },
                        ]
                    }
                ],
            },
            {
                otherParam: {
                    id: data.id
                },
                url: APILXD.setCotentStatus,
                beforeSubmit: (param) => {
                    param.status = Number(param.status);

                    return true;
                },
                callBack: (state, msg) => {
                    //添加成功回调
                    state == 'success' && this.getDocTree();
                }
            });
    }

    //左侧导航栏
    getNavView() {
        let {docTree} = this.state;
        if (!docTree) {
            return null
        }

        if (!docTree.length) {
            return <Empty
                style={{marginTop: "200px"}}
                description={"未创建任何目录"}
            >
                {
                    this.powerForGroupManage.New
                        ? <Button
                            type={"primary"}
                            onClick={() => {
                                this.editGroup()
                            }}
                        >新增目录</Button>
                        : null
                }
            </Empty>
        }

        let getThirdList = (data) => {
            if (!data || !data.length) {
                return null
            }

            let thirdGrade = [];
            data.forEach((item, index) => {
                thirdGrade.push(<Item
                    key={"doc_" + item.id}
                    docitem={item}
                    icon={<Dropdown
                        overlay={<Menu
                            onClick={({ item, key, keyPath, domEvent })=>{
                                domEvent.stopPropagation();

                            }}
                        >
                            {
                                this.powerForContentManage.Edit
                                    ?<Item
                                        onClick={()=>{
                                            this.editDoc(item)
                                        }}
                                    >
                                        编辑此文档标题
                                    </Item>
                                    :null
                            }
                            {
                                this.powerForContentManage.Edit
                                    ?<Item
                                        onClick={()=>{
                                            this.setState({
                                                ifEdit: false,
                                                currentSelected: "doc_" + item.id,
                                            },()=>{
                                                this.getDocContent(()=>{
                                                    this.setState({
                                                        ifEdit: true
                                                    })
                                                })
                                            })
                                        }}
                                    >
                                        编辑此文档内容
                                    </Item>
                                    :null
                            }
                            {
                                this.powerForContentManage.Edit
                                    ?<Item
                                        onClick={()=>{
                                            this.editDocStatus(item)
                                        }}
                                    >
                                        修改发布状态
                                    </Item>
                                    :null
                            }
                            {
                                this.powerForContentManage.Delete
                                    ?<Item
                                        onClick={()=>{
                                            this.deleteDoc(item)
                                        }}
                                    >
                                        删除此文档
                                    </Item>
                                    :null
                            }
                        </Menu>}
                    >
                        {
                            this.props.from === "admin"
                                ?<SettingOutlined
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                />
                                :<div></div>
                        }
                    </Dropdown>}
                >
                    <span style={(item.status === 1 || this.props.from !== "admin")?{}:{color: "darkorange"}}>{item.title}</span>
                </Item>)
            })

            return thirdGrade;
        }

        let getSecondGrade = (data) => {
            if (!data || !data.length) {
                return null
            }

            let secondGrade = [];
            data.forEach((item, index) => {
                if (item.type === 1) {
                    //文档
                    secondGrade.push(<Item
                        key={"doc_" + item.id}
                        docitem={item}
                        icon={<Dropdown
                            overlay={<Menu
                                onClick={({ item, key, keyPath, domEvent })=>{
                                    domEvent.stopPropagation();

                                }}
                            >
                                {
                                    this.powerForContentManage.Edit
                                        ?<Item
                                            onClick={()=>{
                                                this.editDoc(item);
                                            }}
                                        >
                                            编辑此文档标题
                                        </Item>
                                        :null
                                }
                                {
                                    this.powerForContentManage.Edit
                                        ?<Item
                                            onClick={()=>{
                                                this.setState({
                                                    ifEdit: false,
                                                    currentSelected: "doc_" + item.id,
                                                },()=>{
                                                    this.getDocContent(()=>{
                                                        this.setState({
                                                            ifEdit: true
                                                        })
                                                    })
                                                })
                                            }}
                                        >
                                            编辑此文档内容
                                        </Item>
                                        :null
                                }
                                {
                                    this.powerForContentManage.Edit
                                        ?<Item
                                            onClick={()=>{
                                                this.editDocStatus(item)
                                            }}
                                        >
                                            修改发布状态
                                        </Item>
                                        :null
                                }
                                {
                                    this.powerForContentManage.Delete
                                        ?<Item
                                            onClick={()=>{
                                                this.deleteDoc(item)
                                            }}
                                        >
                                            删除此文档
                                        </Item>
                                        :null
                                }

                            </Menu>}
                        >
                            {
                                this.props.from === "admin"
                                    ? <SettingOutlined
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    />
                                    :<div></div>
                            }

                        </Dropdown>}
                    >
                        <span style={(item.status === 1 || this.props.from !== "admin")?{}:{color: "darkorange"}}>{item.title}</span>
                    </Item>)
                } else {
                    //目录
                    secondGrade.push(<SubMenu
                        key={"group_" + item.id}
                        icon={<Dropdown
                            overlay={<Menu
                                onClick={({ item, key, keyPath, domEvent })=>{
                                    domEvent.stopPropagation();

                                }}
                            >
                                {
                                    this.powerForContentManage.New
                                        ? <Item
                                            onClick={()=>{
                                                this.editDoc(null, item.id)
                                            }}
                                        >
                                            在此目录下新增文档
                                        </Item>
                                        :null
                                }
                                {
                                    this.powerForGroupManage.Edit
                                        ?<Item
                                            onClick={()=>{
                                                this.editGroup(item, item.groupid)
                                            }}
                                        >
                                            编辑此目录
                                        </Item>
                                        :null
                                }
                                {
                                    this.powerForGroupManage.Edit && item.child && item.child.length > 1
                                        ?<Item
                                            onClick={()=>{
                                                this.setIndexAction(item.child)
                                            }}
                                        >
                                            排序目录
                                        </Item>
                                        :null
                                }
                                {
                                    this.powerForGroupManage.Delete
                                        ?<Item
                                            onClick={()=>{
                                                this.deleteGroup(item)
                                            }}
                                        >
                                            删除此目录
                                        </Item>
                                        :null
                                }

                            </Menu>}
                        >
                            {
                                this.props.from === "admin"
                                    ?<SettingOutlined
                                        onClick={(e) => {
                                            e.stopPropagation();

                                        }}
                                    />
                                    :<FolderOutlined />
                            }

                        </Dropdown>}
                        title={item.gname}
                    >
                        {getThirdList(item.child)}
                    </SubMenu>)
                }
            })

            return secondGrade;
        }

        let firstGrade = [];

        docTree.forEach((item, index) => {
            firstGrade.push(<SubMenu
                key={"group_" + item.id}
                icon={<Dropdown
                    overlay={<Menu
                        onClick={({ item, key, keyPath, domEvent })=>{
                            domEvent.stopPropagation();

                        }}
                    >
                        {
                            this.powerForGroupManage.New
                                ?<Item
                                    onClick={()=>{
                                        this.editGroup(null, item.id)
                                    }}
                                >
                                    在此目录下新增目录
                                </Item>
                                :null
                        }
                        {
                            this.powerForContentManage.New
                                ?<Item
                                    onClick={()=>{
                                        this.editDoc(null, item.id)
                                    }}
                                >
                                    在此目录下新增文档
                                </Item>
                                :null
                        }
                        {
                            this.powerForGroupManage.Edit
                                ?<Item
                                    onClick={()=>{
                                        this.editGroup(item, item.groupid)
                                    }}
                                >
                                    编辑此目录
                                </Item>
                                :null
                        }
                        {
                            this.powerForGroupManage.Edit && item.child && item.child.length > 1
                                ?<Item
                                    onClick={()=>{
                                        this.setIndexAction(item.child)
                                    }}
                                >
                                    排序目录
                                </Item>
                                :null
                        }
                        {
                            this.powerForGroupManage.Delete
                                ?<Item
                                    onClick={()=>{
                                        this.deleteGroup(item)
                                    }}
                                >
                                    删除此目录
                                </Item>
                                :null
                        }

                    </Menu>}
                >
                    {
                        this.props.from === "admin"
                            ?<SettingOutlined
                                onClick={(e) => {
                                    e.stopPropagation();

                                }}
                            />
                            :<FolderOutlined />
                    }

                </Dropdown>}
                title={item.gname}
            >
                {getSecondGrade(item.child)}
            </SubMenu>)
        })

        return <div>
            <div className={less.navContent}>
                <Menu
                    mode="inline"
                    openKeys={this.state.openKeys || []}
                    selectedKeys={this.state.currentSelected?[this.state.currentSelected]:null}
                    onSelect={({ item, key, keyPath, selectedKeys, domEvent })=>{
                        console.log(item)
                        this.setState({
                            ifEdit: false,
                            currentSelected: "doc_" + (item.props.docitem.id)
                        },()=>{
                            this.getDocContent()
                        })
                    }}
                    onOpenChange={(openKeys)=>{
                        this.setState({
                            openKeys: openKeys
                        })
                    }}
                >
                    {firstGrade}
                </Menu>
            </div>
            <div className={less.navBtn}>
                {
                    this.powerForGroupManage.Edit
                        ? <Button
                            type={"primary"}
                            ghost={true}
                            size={"small"}
                            onClick={() => {
                                this.setIndexAction(this.state.docTree)
                            }}
                        >排序目录</Button>
                        : null
                }
                {
                    this.powerForGroupManage.New
                        ? <Button
                            type={"primary"}
                            size={"small"}
                            ghost={true}
                            onClick={() => {
                                this.editGroup()
                            }}
                        >新增目录</Button>
                        : null
                }
            </div>
        </div>
    }

    //设置目录下的排序
    setIndexAction(arr){
        ModalConfig.show({
                maskClosable: true,
                width: "60%",
                okText: "确认排序",
                cancelText: "放弃排序",
                onOk: () => {
                    let newIndexArr = this.filtersIndexSet.getIndexArr();
                    console.log("新的排序");
                    console.log(newIndexArr);
                    let len = newIndexArr.length;
                    this.commonRequest(APILXD.updateDocSortNo,(code,msg,)=>{
                        message.success(msg);
                        this.getDocTree();
                        ModalConfig.close();
                    },(code,msg)=>{
                        message.error(msg)
                    },{
                        proid: this.props.projectID,
                        data: newIndexArr.map((item, index)=>{
                            return {
                                id: item.id,
                                type: item.type,
                                sortno: len - index
                            }
                        })
                    })
                }
            }, <FiltersIndexSet
                // valueData = {this.lastEditParameter}
                indexArr={arr}
                ref={ref => this.filtersIndexSet = ref}
            />
        )
    }

    deleteGroup(data){
        Modal.confirm({
            title: "提示",
            content: <div>{`确定要删除目录 ${data.gname} 么？`}</div>,
            okType: "danger",
            onOk: ()=>{
                this.commonRequest(APILXD.delGroup,(code,msg)=>{
                    message.success("删除成功");
                    this.getDocTree();
                },(code,msg)=>{
                    Modal.error({
                        title: "删除失败",
                        content: msg,
                    })
                },{
                    id: data.id
                })
            }
        })
    }

    editGroup(data, parentID) {
        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[\s\S]{1,50}$/,
            verMessage: "请输入",
            required: true,
        };
        MB.show(
            {
                title: data ? "编辑目录" : "新增目录",
                okTitle: data ? "保存" : "新增",
                closeTitle: "取消",
            },
            {
                colCount: 1,
                formItemLayout: {
                    labelCol: {span: 8},
                    wrapperCol: {span: 10, offset: 0},
                },
                parameterArr: [
                    {
                        ...defaultOption,
                        field: "gname",
                        name: '目录名称',
                        reg: /^[\s\S]{1,50}$/,
                        option: {
                            placeholder: '请输入目录名称，最多不超过50个字符',
                            defaultValue: data && data.gname,
                        }
                    },
                ],
            },
            {
                otherParam: {
                    proid: this.props.projectID,
                    groupid: parentID || 0
                },
                url: data ? APILXD.editGroup : APILXD.addGroup,
                beforeSubmit: (param) => {
                    param.gname = _.trim(param.gname);

                    if (data) {
                        //编辑
                        param.id = data.id
                    }
                    return true;
                },
                callBack: (state, msg) => {
                    //添加成功回调
                    state == 'success' && this.getDocTree();
                }
            });
    }

    deleteDoc(data){
        Modal.confirm({
            title: "提示",
            content: <div>{`确定要删除文档 ${data.title} 么？`}</div>,
            okType: "danger",
            onOk: ()=>{
                this.commonRequest(APILXD.delContent,(code,msg)=>{
                    message.success("删除成功");
                    this.setState({
                        currentDocObj: null
                    },()=>{
                        this.getDocTree();
                    })

                },(code,msg)=>{
                    Modal.error({
                        title: "删除失败",
                        content: msg,
                    })
                },{
                    id: data.id
                })
            }
        })
    }

    editDoc(data, parentID){
        if(!data && !parentID){
            message.error("请选择目录");
            return;
        }

        if(!data){
            //新增
            this.editDocAc(null, parentID);
        }else{
            this.commonRequest(this.getContentByIdAPI,(code,msg,json)=>{
                this.editDocAc(json, parentID);
            },(code,msg)=>{
                message.error(msg);
            },{
                id: data.id
            })
        }
    }

    editDocAc(data, parentID){
        if(!data && !parentID){
            message.error("请选择目录");
            return;
        }
        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[\s\S]{1,50}$/,
            verMessage: "请输入",
            required: true,
        };
        MB.show(
            {
                title: data ? "编辑文档" : "新增文档",
                okTitle: data ? "保存" : "新增",
                closeTitle: "取消",
            },
            {
                colCount: 1,
                formItemLayout: {
                    labelCol: {span: 8},
                    wrapperCol: {span: 10, offset: 0},
                },
                parameterArr: [
                    {
                        ...defaultOption,
                        field: "title",
                        name: '文档标题',
                        reg: /^[\s\S]{1,50}$/,
                        option: {
                            placeholder: '请输入文档标题，最多不超过50个字符',
                            defaultValue: data && data.title,
                        }
                    },
                ],
            },
            {
                otherParam: {
                    proid: this.props.projectID,
                    groupid: data?data.groupid :(parentID)
                },
                url: data ? APILXD.editContent : APILXD.addContent,
                beforeSubmit: (param) => {
                    param.title = _.trim(param.title);

                    if (data) {
                        //编辑，status不传不改状态
                        param.id = data.id;
                        param.content = data.content;
                    }else{
                        param.status = 2;//默认新增的时候不发布
                        param.content = `文档已经初始化，请开始编辑吧...`
                    }


                    return true;
                },
                callBack: (state, msg, json) => {
                    console.log(json)
                    //添加成功回调
                    if(state == 'success'){
                        this.getDocTree();

                        this.setState({
                            ifEdit: false,
                            currentSelected: "doc_" + (data?data.id:json.id)
                        },()=>{
                            this.getDocContent(()=>{
                                if(!data){
                                    this.setState({
                                        ifEdit: true
                                    })
                                }
                            });
                        })
                    }
                }
            });
    }

    saveDocContent(contentStr){
        let data = this.state.currentDocObj;
        if(!data){
            message.error("保存出错");
            return ;
        }

        console.log("查看需要保存的data");
        console.log(data);

        this.commonRequest(APILXD.editContent,(code,msg, json)=>{
            message.success(msg);
            this.setState({
                ifEdit: false,
                currentSelected: "doc_" + (data?data.id:json.id)
            },()=>{
                this.getDocContent();
            })
        },(code, msg)=>{
            message.error(msg);
        },{
            id:  data.id,
            proid: data.proid,
            title: data.title,
            groupid: data.groupid,
            content: contentStr
        })
    }
}

export default DocPage;