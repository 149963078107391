import React from 'react';
import ListPage from '../../base/ListPage.js';
import less from './ProjectList.less';
import APILXD from '../../../http/APILXD.js';
import ModalDel from '../../../components/modalDelete/ModalDelete.js';
import {Button,message,Popover, Tag} from 'antd';
import TimeHelp from "../../../tool/TimeHelp";
import InputWithText from "../../../components/inputWidthText/InputWidthText"
import ModalBase from "../../../components/modalBase/index";
import HttpTool from "../../../tool/HttpTool";

const ModalDelete = new ModalDel();
const MB = new ModalBase();

class ProjectList extends ListPage {
    constructor(props) {
        super(props);
    }

    base_getListConfig() {
        return {
            table: {
                columns: this.getTableColumns(),
                url: APILXD.getAllProjects,
                otherParam: null,
            },
            new: {
                defaultValue: '+ 创建新项目',
                action: () => {
                    this.editProject()
                }
            },
            extraBox: ()=>{
                return <div>
                    <Button
                        type={"primary"}
                        onClick={()=>{
                            this.refreshDocToES()
                        }}
                    >刷新文档缓存</Button>
                </div>
            }
        }
    }

    refreshDocToES(){
        HttpTool.post(APILXD.refreshAllContentToES,(code,msg)=>{
            message.success(msg);
        },(code,msg)=>{
            message.error(msg)
        },{})
    }

    editProject(data){
        let addData = this.getAddData(data);
        MB.show(
            {
                title: data ? "编辑项目" : "新增项目",
                okTitle: data ? "保存" : "新增",
                closeTitle: "取消",
            },
            addData.props,
            {
                otherParam: data ? {id: data.id} : {},
                url: data ? APILXD.editProject : APILXD.addProject,
                beforeSubmit: (param) => {
                    param.name = _.trim(param.name);
                    param.descs = _.trim(param.descs);
                    param.sortno =Number(_.trim(param.sortno));

                    if(data){
                        param.status = data.status;
                    }else{
                        param.status = Number(param.status);
                    }
                    return true;
                },
                callBack: (state, msg) => {
                    //添加成功回调
                    state == 'success' && this._loadDataForNet();
                }
            });
    }


    getAddData(data) {
        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[\s\S]{1,50}$/,
            verMessage: "请输入",
            required: true,
        };
        let props = {
            colCount: 1,
            formItemLayout: {
                labelCol: {span: 8},
                wrapperCol: {span: 10, offset: 0},
            },
            parameterArr: [
                {
                    ...defaultOption,
                    field: "name",
                    name: '项目名',
                    reg: /^[\s\S]{1,50}$/,
                    option: {
                        placeholder: '请输入会员等级名称，最多不超过50个字符',
                        defaultValue: data && data.name,
                    }
                },
                {
                    ...defaultOption,
                    field: "descs",
                    name: '项目描述',
                    type: 'TextArea',
                    reg: /^[\s\S]{1,250}$/,
                    option: {
                        placeholder: '请输入项目描述，最多不超过1000个字符',
                        defaultValue: data && data.descs,
                        maxLength: '1000',
                        autoSize: {minRows: 4, maxRows: 6}
                    }
                },
                {
                    ...defaultOption,
                    field: "sortno",
                    name: '展示排序',
                    type: 'diy',
                    component: InputWithText,
                    reg: (v)=>{
                        return v && v>0
                    },
                    option: {
                        placeholder: '越大展示越靠前',
                        defaultValue: data && data.sortno,
                        style: {
                            width: '50%'
                        },
                        precision: 0,
                        max: 1000000000,
                        min: 1
                    }
                },
                {
                    ...defaultOption,
                    field: data?null:"status",
                    type: "select",
                    selectType: "value",
                    name: '发布状态',
                    reg: (v)=>{
                        return !!v
                    },
                    option: {
                        placeholder: '请选择发布状态',
                        defaultValue: data ? {
                            key: "" + data.status,
                            value: "" + data.status,
                        }:undefined,
                    },
                    data: [
                        {
                            title: <span style={{color: "limegreen"}}>发布</span>,
                            value: "1",
                        },
                        {
                            title: <span style={{color: "gray"}}>不发布</span>,
                            value: "2",
                        },
                    ]
                },
            ],
        };
        return {
            props,
            otherParam: null
        }
    }

    editProjectStatus(data){
        MB.show(
            {
                title: "修改状态",
                okTitle: "保存",
                closeTitle: "取消",
            },
            {
                colCount: 1,
                formItemLayout: {
                    labelCol: {span: 8},
                    wrapperCol: {span: 10, offset: 0},
                },
                parameterArr: [
                    {
                        field: "name",
                        name: '项目名',
                        option: {
                            defaultValue: data && data.name,
                        }
                    },
                    {
                        field: "status",
                        type: "select",
                        selectType: "value",
                        name: '发布状态',
                        reg: (v)=>{
                            return !!v
                        },
                        option: {
                            placeholder: '请选择发布状态',
                            defaultValue: data ? {
                                key: "" + data.status,
                                value: "" + data.status,
                            }:undefined,
                        },
                        data: [
                            {
                                title: <span style={{color: "limegreen"}}>发布</span>,
                                value: "1",
                            },
                            {
                                title: <span style={{color: "darkorange"}}>不发布</span>,
                                value: "2",
                            },
                        ]
                    },
                ],
            },
            {
                otherParam: {id: data.id},
                url: APILXD.setProjectStatus,
                beforeSubmit: (param) => {
                    param.status = Number(param.status);
                    return true;
                },
                callBack: (state, msg) => {
                    //添加成功回调
                    state == 'success' && this._loadDataForNet();
                }
            });
    }


    /**
     *  列表数据配置
     */
    getTableColumns() {
        return [
            {
                title: '项目名',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '描述',
                dataIndex: 'descs',
                key: 'descs',
                render: (text)=>{
                    return (text && text.length > 20)?<Popover content={text}>{text.slice(0,20) + "..."}</Popover>:text
                }
            },
            {
                title: '文档数',
                dataIndex: 'docno',
                key: 'docno',
            },
            {
                title: '创建时间',
                dataIndex: 'addtime',
                key: 'addtime',
                render: (text)=>{
                    return TimeHelp.getYMDHM(text)
                }
            },
            {
                title: '排序',
                dataIndex: 'sortno',
                key: 'sortno',
            },
            {
                title: '发布状态',
                dataIndex: 'status',
                key: 'status',
                render: (text)=>{
                    return {
                        1: <Tag color={"limegreen"}>发布</Tag>,
                        2:<Tag color={"darkorange"}>不发布</Tag>,
                    }[text]
                }
            },
            {
                title: '操作',
                width: 260,
                render: (text, record, index) => {
                    return (<div>
                        {
                            this.powerConfig.Edit
                                ?(  <div
                                    className={less.btnDetail}
                                    onClick={() => {
                                        ___.jumpToDocPageForAdmin({
                                            projectID: record.id
                                        })
                                    }}
                                >查看
                                </div>)
                                :null
                        }
                        {
                            this.powerConfig.Edit
                                ?(  <div
                                    className={less.btnEdit}
                                    onClick={() => {
                                        this.editProject(record)
                                    }}
                                >编辑
                                </div>)
                                :null
                        }
                        {
                            this.powerConfig.Edit
                                ?(  <div
                                    className={less.btnEdit}
                                    onClick={() => {
                                        this.editProjectStatus(record)
                                    }}
                                >修改状态
                                </div>)
                                :null
                        }
                        {
                            this.powerConfig.Delete
                                ?(<div
                                    className={less.btnDelete}
                                    onClick={() => {
                                        ModalDelete.show({
                                                title: "提示",
                                                okTitle: "确定",
                                                closeTitle: "取消",
                                            },
                                            {}
                                            ,
                                            {
                                                otherParam: {id: record.id},
                                                content: record.name,
                                                url: APILXD.delProject,
                                                apiType: 'post',
                                                tip: '删除后不可恢复',
                                                callBack: (state) => {
                                                    //删除成功回调
                                                    state === "success" && this._loadDataForNet();
                                                }
                                            });
                                    }}
                                >删除
                                </div>)
                                :null
                        }
                    </div>)
                }
            },
        ]
    }
}

export default ProjectList;