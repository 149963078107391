import React from "react";
import DocPage from "../docPage/DocPage";
import HttpTool from "../../../tool/HttpTool";
import {Modal, Spin, Empty} from "antd";
import less from "./DocPageForUser.less";

export default class DocPageForUser extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            projectData: null,
            loading:false,
        }

        this.urlParams = ___.getParams();
    }

    componentDidMount() {
        if(!this.urlParams.projectID){
            this.loadProject()
        }
    }

    /**
     * 统一的请求展示交互，目前是loading
     * @param path          请求地址
     * @param param         请求参数
     * @param successCB     成功的回调
     * @param failureCB     失败的回调
     * @param showAction    是否展示交互，默认打开
     */
    commonRequest(path, successCB, failureCB, param, showAction = true) {
        if (!showAction) {
            HttpTool.post(path, successCB, failureCB, param);
        } else {
            let newSuccessCB = (code, msg, obj, option) => {
                this.setState({
                    loading: false,
                }, () => {
                    successCB && successCB(code, msg, obj, option);
                })
            };
            let newFailureCB = (code, msg, obj, option) => {
                this.setState({
                    loading: false,
                }, () => {
                    failureCB && failureCB(code, msg, obj, option);
                })
            };

            this.setState({
                loading: true
            }, () => {
                HttpTool.post(path, newSuccessCB, newFailureCB, param);
            })
        }
    }

    loadProject(){
        this.commonRequest("/app/doc/getAllProjects",(code,msg,json)=>{
            this.setState({
                projectData: json
            })
        },(code,msg)=>{
            Modal.error({
                title: "请求错误",
                content: msg
            })
        },{})
    }

    render(){
        let {loading} = this.state;
        return <div className={less.bg}>
            {
                loading
                    ?<div className={less.spinStyle}><Spin size={"large"} spinning={true}></Spin></div>
                    :null
            }
            {
                this.urlParams.projectID
                    ?<DocPage
                        from={"user"}
                        projectID={this.urlParams.projectID?Number(this.urlParams.projectID):null}
                        groupID={this.urlParams.groupID?Number(this.urlParams.groupID):null}
                        contentID={this.urlParams.contentID?Number(this.urlParams.contentID):null}
                    />
                    :(this.getProjectShow())
            }
        </div>
    }

    getProjectShow(){
        let {projectData} = this.state;

        if(!projectData){
            return null
        }

        if(!projectData.length){
            return <Empty
                style={{marginTop: "200px"}}
                description={"无项目"}
            />
        }

        let proArr =  projectData.map((project)=>{
            return <div
                key={"pro_" + project.id}
                className={less.projectBox}
                onClick={()=>{
                    ___.jumpToDocPageForUser({
                        projectID: project.id
                    })
                }}
            >
                <div className={less.proTitle}>{project.name}</div>
                <div className={less.desBox}>{project.descs}</div>
                <div className={less.noBox}>文档数：{project.docno}</div>
            </div>
        })

        return <div>
            <div className={less.mainTitle}
            >
                <span className={less.logo}></span>
                文档中心
            </div>
            <div className={less.outer}>
                <div className={less.proContentBox}>{proArr}</div>
            </div>
        </div>
    }
}