import React from 'react';
import {
  message,
  Spin
} from 'antd';
import styles from "./MyMarkDown.less";

import Editor from "@toast-ui/editor"
import '@toast-ui/editor/dist/i18n/zh-cn.js';

import './css/toastui-editor.css'; // Editor's Style
import './css/toastui-editor-viewer.css'; // Editor's Style

import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';

export default class MarkDownShow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    }
  }

  componentDidMount() {
    this.editor = new Editor.factory({
      el: this.editorDiv,
      initialValue: this.props.value,
      viewer: true,
      height: this.editorDiv.clientHeight,
      width: this.editorDiv.clientWidth,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value && nextProps.value !== this.props.value) {
      this.addValue(nextProps.value);
    }
  }

  addValue(value) {
    this.editor.setMarkdown(value)
  }

  render() {
    let {loading} = this.state;
    return (
      <div className={styles.mainShow}>
        {
          loading
            ?<div className={styles.loadLayer}><Spin spinning={true} size={"large"}/></div>
            :null
        }
        <div
          style={{width: "100%", height: "100%"}}
          ref={(ref) => {
            this.editorDiv = ref;
          }}>
        </div>
      </div>
    );
  }
}






