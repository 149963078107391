import React from 'react';
import {
  message,
  Spin
} from 'antd';
import styles from "./MyMarkDown.less";

import Editor from "@toast-ui/editor"
import '@toast-ui/editor/dist/i18n/zh-cn.js';

import './css/toastui-editor.css'; // Editor's Style
import './css/toastui-editor-viewer.css'; // Editor's Style

import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';

import chart from '@toast-ui/editor-plugin-chart';

import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight-all.js';

import '@toast-ui/editor-plugin-table-merged-cell/dist/toastui-editor-plugin-table-merged-cell.css';
import tableMergedCell from '@toast-ui/editor-plugin-table-merged-cell';

import uml from '@toast-ui/editor-plugin-uml';

import UploadHelp from "../../../../components/upLoad/UploadHelp";

export default class MyMarkDown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    }

    this.upload = new UploadHelp();



  }

  componentDidMount() {
    const chartOptions = {
      minWidth: 100,
      maxWidth: 600,
      minHeight: 100,
      maxHeight: 300
    };


    this.editor = new Editor({
      el: this.editorDiv,
      initialValue: this.props.value,
      initialEditType: "wysiwyg",//'markdown',
      previewStyle: 'vertical',
      // language:this.props.locale==="cn"?'zh-CN':"en-US",
      language: 'zh-CN',
      placeholder: this.props.placeholder,
      height: this.editorDiv.clientHeight,
      width: this.editorDiv.clientWidth,
      plugins: [[chart,chartOptions], codeSyntaxHighlight, colorSyntax, tableMergedCell, uml],
      hooks: {
        addImageBlobHook: (file, insertActoin) => {
          // 此处填写自己的上传逻辑，url为上传后的图片地址
          console.log("aa", file, insertActoin)

          let successCB = (code, msg, json, option) => {
            insertActoin(json.url);
            this.setState({
              loading: false,
            });
          };
          let failureCB = (code, msg) => {
            message.error('上传图片失败');
            this.setState({
              loading: false,
            });
          };

          let suffixList = file && file.name && file.name.split('.');
          if (suffixList.length <= 1) {
            message.warning('文件选择出错');
            return;
          }
          let suffix = suffixList[suffixList.length - 1];
          if (!suffix) {
            message.warning('文件选择出错');
            return;
          }

          this.setState({
            loading: true,
          }, () => {
            this.upload.upload(file, suffix, successCB, failureCB);
          });
        }
      }
    });
    this.editor.on("change", (v) => {
      this.props?.onChange?.(this.editor.getMarkdown());
    })

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value && nextProps.value !== this.props.value) {
      this.addValue(nextProps.value);
    }
  }

  addValue(value) {
    if (value !== this.editor.getMarkdown()) {
      this.editor.setMarkdown(value)
    }
  }

  getValue(){
    return this.editor.getMarkdown()
  }

  render() {
    let {loading} = this.state;
    return (
      <div className={styles.main}>
        {
          loading
            ?<div className={styles.loadLayer}><Spin spinning={true} size={"large"}/></div>
            :null
        }
        <div
          style={{width: "100%", height: "100%"}}
          ref={(ref) => {
            this.editorDiv = ref;
          }}>
        </div>
      </div>
    );
  }
}






